import React from 'react';
import Loadable from 'react-loadable';
import { CircularProgress } from '@material-ui/core';
import dotProp from 'dot-prop';
import Button from '../components/Button';
// cache of dynamically imported components
const componentCache = {};
// cache of Loadable instances
const LoadableCache = {};
const map = {
  // Accordion: () => import('../components/Accordion'),
  // ActivityCard: () => import('../components/ActivityCard'),
  Animation: () => import('../components/Animation'),
  AuditTrail: () => import('../components/AuditTrail'),
  Lightbox: () => import('../components/Lightbox'),
  Banner: () => import('../components/Banner'),
  Button: () => import('../components/Button'),
  BulkUpload: () => import('../components/BulkUpload'),
  Grid: () => import('../components/layouts/Grid'),
  ButtonLink: () => import('../components/ButtonLink'),
  // Card: () => import('../components/Card'),
  // Carousel: () => import('../components/Carousel'),
  CheckPrivileges: () => import('../components/CheckPrivileges'),
  Center: () => import('../components/layouts/Center'),
  Confirm: () => import('../components/Confirm'),
  DashboardPage: () => import('../components/engine/EndUserDashboardEngine'),
  Drawer: () => import('../components/Drawer'),
  Divider: () => import('../components/Divider'),
  EndUserForm: () => import('../components/EndUserForm'),
  Stepper: () => import('../components/Stepper'),
  Clickable: () => import('../components/Clickable'),
  // FadeCard: () => import('../components/FadeCard'),
  Fields: {
    Checkbox: () => import('../components/form/Checkbox'),
    Currency: () => import('../components/form/Currency'),
    Date: () => import('../components/form/DateField'),
    Dropdown: () => import('../components/form/Dropdown'),
    Instructions: () => import('../components/form/Instructions'),
    InstructionalText: () => import('../components/form/InstructionalText'),
    MultipleChoice: () => import('../components/form/MultipleChoice'),
    MustMatch: () => import('../components/form/MustMatch'),
    Number: () => import('../components/form/Number'),
    Text: () => import('../components/form/TextField'),
    SubmitButton: () => import('../components/form/SubmitButton'),
    Email: () => import('../components/form/Email'),
    Password: () => import('../components/form/Password'),
    FileUpload: () => import('../components/form/FileUpload'),
    PasswordWithShow: () => import('../components/form/PasswordWithShow'),
    ProfileField: () => import('../components/form/ProfileField'),
    YesNo: () => import('../components/form/YesNo'),
    ConfirmationBox: () => import('../components/form/ConfirmationBox')
  },
  // FlipCard: () => import('../components/FlipCard'),
  Form: () => import('../components/Form'),
  FormBuilder: () => import('../components/engine/FormBuilderEngine'),
  FormGrid: () => import('../components/refactor/FormGrid'),
  FormGridWrapper: () => import('../components/refactor/FormGridWrapper'),
  FormPreviewPage: () => import('../components/FormPreviewPage'),
  ImportFormPage: () => import('../components/engine/ImportFormPageEngine'),
  FormsPage: () => import('../components/layouts/FormsPage'),
  GroupCreationGrid: () => import('../components/refactor/GroupCreationGrid'),
  Image: () => import('../components/Image'),
  IntegrationBuilder: () =>
    import('../components/integration/engine/IntegrationBuilderEngine'),
  IntegrationGrid: () => import('../components/integration/IntegrationGrid'),
  Link: () => import('../components/Link'),
  LoginPage: () => import('../components/engine/LoginPageEngine'),
  // LoginPage: () => import('../components/LoginPage'),
  ManageEmailNotificationsSection: () =>
    import('../components/ManageEmailNotificationsSection'),
  ManageIntegrationsSection: () =>
    import('../components/ManageIntegrationsSection'),
  ManageTasksSection: () => import('../components/ManageTasksSection'),
  ManageSystemSettingsSection: () =>
    import('../components/ManageSystemSettingsSection'),
  // NavBar: () => import('../components/NavBar'),
  // NavLinks: () => import('../components/NavLinks'),
  NavPage: () => import('../components/layouts/ng-layouts/NavPage'),
  ErrorPage: () => import('../components/layouts/ng-layouts/ErrorPage'),
  DualLogin: () => import('../components/engine/DualLoginEngine'),
  DsConnected: () => import('../components/engine/DsConnectedEngine'),
  DsSigned: () => import('../components/engine/DsSignedEngine'),
  NotificationSettings: () =>
    import('../components/notification/NotificationSettings'),
  Paper: () => import('../components/layouts/Paper'),
  ReportBuilder: () => import('../components/ReportBuilder'),
  ReportsList: () => import('../components/refactor/ReportsList'),
  ReportsPage: () => import('../components/ReportsPage'),
  NewReportsPageEngine: () =>
    import('../components/reports/engine/NewReportsPageEngine'),
  NewReportsDashboardBuilderPageEngine: () =>
    import('../components/reports/engine/NewReportsDashboardBuilderPageEngine'),
  NewReportsReportBuilderPageEngine: () =>
    import('../components/reports/engine/NewReportsReportBuilderPageEngine'),
  NewReportsDashboardPageEngine: () =>
    import('../components/reports/engine/NewReportsDashboardPageEngine'),
  Reports: {
    // StatusOverview: () => import('../components/reports/StatusOverview'),
    BarChart: () => import('../components/reports/BarChart'),
    Counter: () => import('../components/reports/Counter'),
    LineChart: () => import('../components/reports/LineChart'),
    PieChart: () => import('../components/reports/PieChart'),
    DataTable: () => import('../components/reports/DataTable'),
    Text: () => import('../components/reports/Text'),
    Grid: () => import('../components/reports/Grid')
  },
  Route: () => import('../components/Route'),
  SystemSettings: () => import('../components/SystemSettings'),
  TaskSettings: () => import('../components/tasks/engine/TaskSettingsEngine'),
  APIDocumentation: () => import('../components/APIDocumentation'),
  APIKey: () => import('../components/APIKey'),
  SamlError: () => import('../components/SamlError'),
  SamlSetup: () => import('../components/SamlSetup'),
  // ScrollMagic: () => import('../components/ScrollMagic'),
  // ScrollTimeline: () => import('../components/ScrollTimeline'),
  // SidebarPage: () => import('../components/layouts/SidebarPage'),
  SetPassword: () => import('../components/engine/LoginPageEngine'),
  SimplePage: () => import('../components/layouts/SimplePage'),
  NavPageNoFooter: () =>
    import('../components/layouts/ng-layouts/NavPageNoFooter'),
  NavPageNoNavShadow: () =>
    import('../components/layouts/ng-layouts/NavPageNoNavShadow'),
  // Spacer: () => import('../components/layouts/Spacer'),
  // StoreValue: () => import('../components/StoreValue'),
  // SVGAnimation: () => import('../components/SVGAnimation'),
  Table: () => import('../components/Table'),
  TablePage: () => import('../components/layouts/TablePage'),
  Text: () => import('../components/Text'),
  TextList: () => import('../components/TextList'),
  ThemeProvider: () => import('../components/ThemeProvider'),
  TodoGrid: () => import('../components/refactor/TodoGrid'),
  Tooltip: () => import('../components/Tooltip'),
  UserGrid: () => import('../components/refactor/UserGrid'),
  UserManager: () => import('../components/UserManager'),
  WorldCheckPage: () => import('../components/engine/WorldCheckPageEngine'),
  DevForm: () => import('../components/DevForm')
  // Video: () => import('../components/Video')
};
/**
 * Returns a dynamic import fetching the JS of the component.
 *
 * @param {string} type the component name
 */
export const getComponentImport = type => {
  const fn = dotProp.get(map, type);
  if (!fn) {
    console.warn(`type not found: ${type}`);
    return Promise.reject();
  }
  return retry(type, fn);
};
/**
 * Returns an instance of a dynamic import for a given component. Memoizes the result in componentCache.
 */
export const getComponent = type => {
  if (type in componentCache) {
    return componentCache[type];
  }
  componentCache[type] = getComponentImport(type);
  return componentCache[type];
};
function refreshPage() {
  window.location.reload(false);
}
function retry(
  type,
  fn,
  retriesLeft = 5,
  interval = 1000,
  initialInterval = 1000,
  maxRetries = 5
) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          //console.log(`danny - retry  retriesLeft.${type}`, error)
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            console.log(
              `rejecting path - ${type}, interval=${interval}`,
              error
            );
            reject(error);
            return;
          }
          //console.log(`not rejected - ${type}, interval=${interval}`, error);
          // Passing on "reject" is the important part
          retry(
            type,
            fn,
            retriesLeft - 1,
            (maxRetries - retriesLeft + 1) * initialInterval
          ).then(resolve, reject);
        }, interval);
      });
  });
}
/**
 * Returns an instance of a Loadable for a given component. Memoizes the result in LoadableCache.
 */
export const getLoadable = type => {
  if (type in LoadableCache) {
    return LoadableCache[type];
  }
  LoadableCache[type] = Loadable({
    loader: () => getComponent(type),
    loading: props =>
      props.error ? (
        <div>
          <i>Something went wrong. {console.error(type)}</i>
          <br />
          <Button onClick={refreshPage}>Click to reload!</Button>
        </div>
      ) : props.pastDelay ? (
        <div
          style={{
            textAlign: 'center'
          }}
        >
          <CircularProgress />
        </div>
      ) : null
  });
  return LoadableCache[type];
};
/**
 * Given an element object, preloads its Loadable instance using `Loadable.preload()`. Recurses through the element's props looking for nested Loadables to preload as well.
 */
export const preloadLoadables = el => {
  // only process objects
  if (typeof el !== 'object') {
    return;
  }
  // recurse through props
  Object.keys(el).forEach(key => {
    if (el[key]) {
      if (Array.isArray(el[key])) {
        // if an array, loop
        el[key].map(preloadLoadables);
      } else {
        preloadLoadables(el[key]);
      }
    }
  });
  // get Loadable and preload it
  if (el.type && dotProp.get(map, el.type)) {
    getLoadable(el.type).preload();
  }
};
